import React, { useEffect, useState } from "react"

import Layout from "../../../structure/Layout/Layout"
import Seo from "../../../structure/Seo/Seo"
import Editor from "../../../element/Editor/Editor";
import "./styles.scss";
import { clearLSFilters } from "../../../../utils/Data";
import CitySkyline from "../../../element/Svg/CitySkyline";

const MethodologyPage = () => {
  const [path, setPath] = useState("");

  useEffect(() => {
    clearLSFilters();
  }, []);

  return (
    <Layout current='a-propos' subcurrent='methodologie'>
      <Seo title="Méthodologie | Vu des Quartiers" />
      <div className="page page-grid page-cold page-methodology" data-theme="secondary-50">
        <div className="page_inner">
          <section className="section section-main section-page section-header mini section-description">
            <div className="section_inner radius radius-small radius-bottom radius-left" data-theme="primary">
              <div className="section-title">
                <h1 className="small"><b>Méthodologie</b></h1>
              </div>
            </div>
            <CitySkyline />
          </section>
          <section className="section section-secondary section-page">
            <div className="section_inner radius radius-small radius-bottom radius-right" data-theme="white">
              <Editor content={`
                <div class="editor-block">
                  <p>L’inscription dans l’annuaire se fait sur la base du volontariat. Toute personne agissant d’une façon ou d’une autre dans la vie de son quartier est susceptible de s’inscrire et de créer sa fiche.</p>
                </div>

                <div class="editor-paragraph">
                  <p>Les contacts référencés n’ont pas été sélectionnés ni validés par l’association ou l’un de ses partenaires institutionnels ou privés. Les journalistes accrédités qui utilisent l’annuaire vérifient les informations et la validité des témoignages recueillis comme le demande l’exercice normal de leur profession.<p>
                </div>

                <div class="editor-paragraph">
                  <p>La qualité d’interlocuteur dans l’annuaire n’est pas connue des partenaires institutionnels de l’association Vu des Quartiers. Seuls les journalistes accrédités par l’association et l’animateur de l’association peuvent avoir accès à l’annuaire.<p>
                </div>

                <div class="editor-paragraph">
                  <p>Pour s’inscrire dans l’annuaire, il est nécessaire de remplir <a class="link link-secondary" href="https://vudesquartiers.fr/membre/" target="_blank" rel="noopenner"><span>le formulaire disponible à ce lien.</span></a> Les réponses à ce questionnaire sont nécessaires pour vous inscrire et devenir un contact.<p>
                </div>

                <div class="editor-block">
                  <h2>Sont demandés aux personnes souhaitant s’inscrire dans l’annuaire :</h2>
                </div>

                <div class="editor-paragraph">
                  <ul>
                    <li><div><span> L’adresse e-mail, nom, prénom, sexe et date de naissance</span></div></li>
                    <li><div><span>Des précisions sur l’activité et les thématiques sur lesquelles la personne souhaite intervenir</span></div></li>
                    <li><div><span>Quelques mots pour se présenter</span></div></li>
                    <li><div><span>Le territoire concerné (adresse et quartier)</span></div></li>
                    <li><div><span>Numéro de téléphone</span></div></li>
                    <li><div><span>Les heures auxquelles la personne ne souhaite pas être contactée</span></div></li>
                  </ul>
              </div>

              <div class="editor-paragraph">
                  <p>Ces réponses ne seront visibles que par les journalistes accrédités et par l’animateur de l’association. Tout contact de l’annuaire peut demander modification de sa fiche ou se désinscrire à tout moment en contactant l’association à l'adresse <a href="mailto:contact@vudesquartiers.fr" class="link link-secondary"><span>contact@vudesquartiers.fr</span></a></p>
                </div>
            `} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default MethodologyPage



